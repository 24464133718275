import React from 'react'
import Img from 'gatsby-image'
import Image from '../../../image'
import { MdDone } from "react-icons/md";
import Button from '../../button'
import {
    PricingTableWrap,
    PricingTableInner,
    PricingTableHead,
    PricingTableTitle,
    PricingTableImage,
    PricingTablePrice,
    PricingTableBody,
    PricingTableBtn,
    PricingTableList,
    PricingTableFeatureMark
} from './style'


const PricingTable = ({ title, period, image, price, isFeatured, path, features }) => {
    const btnStyle = {
        varient: !isFeatured ? 'outlined' : 'contained',
        hover: !isFeatured ? "true" : 2
    }
    return (
        <PricingTableWrap>
            <PricingTableInner>
                {isFeatured && (
                    <PricingTableFeatureMark>
                        <span>Popular Choice</span>
                    </PricingTableFeatureMark>
                )}
                <PricingTableHead>
                    {title && <PricingTableTitle>{title}</PricingTableTitle>}
                    {price && (
                        <PricingTablePrice>
                            <sup>£</sup>
                            <h6 style={{ zIndex: 1 }}>{price}</h6>
                            <sub>{
                                period === 'month-user' 
                                    ? '/ User per Month'
                                    : period === 'month-device'
                                        ? '/ Device per Month'
                                        : period === 'hour'
                                            ? '/ hour'
                                            : period === 'month'
                                                ? '/ month'
                                                : period === 'year'
                                                    ? '/ year'
                                                    : ''
                            }</sub>
                        </PricingTablePrice>
                    )}
                </PricingTableHead>
                <PricingTableBody>
                    {path && (
                        <PricingTableBtn>
                            <Button {...btnStyle} to={path}>Enquire Now</Button>
                        </PricingTableBtn>
                    )}
                    {features && (
                        <PricingTableList>
                            {features.map(feature => <li key={feature.id}><MdDone /><span>{feature.text}</span></li>)}
                        </PricingTableList>
                    )}
                </PricingTableBody>
            </PricingTableInner>
        </PricingTableWrap>
    )
}

export default PricingTable
