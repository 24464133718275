import React from 'react';
import { Container, Row, Col } from '../../components/ui/wrapper'
import Heading from '../../components/ui/heading'
import { SectionWrap, StepWrap, GradationRow } from './service-template.style'
import Gradation from '../../components/ui/gradation'

const ServiceTemplate = ({ pageData }) => {
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col md={9}>
                        <Heading>{pageData.gradation.title}</Heading>
                    </Col>
                    <Col md={3}>
                        <StepWrap>
                            <h3><mark>{`0${pageData.gradation.gradations.length}`}</mark>Steps</h3>
                        </StepWrap>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <GradationRow>
                            {pageData.gradation.gradations.map((gradation, i) => (
                                <Gradation
                                    key={gradation.id}
                                    number={i + 1}
                                    isLast={i === pageData.gradation.gradations.length - 1}
                                    title={gradation.title}
                                    desc={gradation.desc}
                                    path={gradation.path}
                                    buttonText={gradation.buttonText}
                                />
                            ))}
                        </GradationRow>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

export default ServiceTemplate;