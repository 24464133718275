import React from 'react';
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { BoxIconWrap, BoxIconInner, BoxIconTop, IconWrap, Heading, BoxIconBottom, Text } from './box-icon.style'

const BoxIcon = ({ icon, title, desc }) => {
    return (
        <BoxIconWrap>
            <BoxIconInner>
                <BoxIconTop>
                    {title && <Heading>{title}</Heading>}
                </BoxIconTop>
                {desc && (
                    <BoxIconBottom>
                        <Text>{desc}</Text>
                    </BoxIconBottom>
                )}
            </BoxIconInner>
        </BoxIconWrap>
    )
}

BoxIcon.defaultProps = {
    title: '',
    desc: '',
    icon: {}
}

BoxIcon.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string
}

export default BoxIcon;