import React from 'react';
import Heading from '../../components/ui/heading'
import { FeatureArea } from './service-template.style'
import BoxIcon from '../../components/box-icon/layout-three'
import { Container, Row, Col } from '../../components/ui/wrapper'

const ServiceTemplate = ({ pageData, ...restProps }) => {
    const {
        featureStyels: { featureTitle }
    } = restProps;

    return pageData.features && (
        <FeatureArea>
            <Container>
                {pageData.features.title && (
                    <Row>
                        <Col col="12">
                            <Heading {...featureTitle}>{pageData.features.title}</Heading>
                        </Col>
                    </Row>
                )}
                {pageData.features.items && (
                    <Row>
                        {pageData.features.items.map(feature => (
                            <Col lg={6} key={feature.id}>
                                <BoxIcon
                                    title={feature.title}
                                    icon={feature.icon}
                                    desc={feature.desc}
                                />
                            </Col>
                        ))}
                    </Row>
                )}
            </Container>
        </FeatureArea>
    )
}

export default ServiceTemplate;