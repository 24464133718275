import React from 'react';
import styled from 'styled-components'
import { Container, Row, Col } from '../../components/ui/wrapper'
import Image from '../../components/image'
import VideoButton from '../../components/ui/video-button'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import AccordionWrap from '../../components/ui/accordion'
import { FaqArea, VideoBoxWrap, VideoBtnWrap } from './service-template.style'
import ContactForm from '../../components/forms/contact-form'
import Heading from '../../components/ui/heading'

const EquireHeading = styled(Heading)`
    font-size: 28px;
    margin-bottom: 10px;
`

const Faq = ({ pageData, modalVideoOpen }) => {
    return (pageData.faq || pageData.video) && (
        <FaqArea>
            <Container>
                <Row>
                    {pageData.faq && (
                        <Col lg={6} pr="3rem">
                            <AccordionWrap layout="two">
                                <Accordion preExpanded={"0"}>
                                    {
                                        pageData.faq.map((el, index) => {
                                            return (
                                                <AccordionItem id={el.id} key={el.id}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            {el.title}
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>{el.desc}</p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            )
                                        })
                                    }
                                </Accordion>
                            </AccordionWrap>
                        </Col>
                    )}
                    {pageData.video && (
                        <Col lg={6}>
                            <VideoBoxWrap>
                                {pageData.video.preview_image && <Image fluid={pageData.video.preview_image.childImageSharp.fluid} alt="Video preview" align="left" />}
                                <VideoBtnWrap>
                                    <VideoButton skin="primary" onClick={modalVideoOpen} />
                                </VideoBtnWrap>
                            </VideoBoxWrap>
                        </Col>
                    )}
                    {!pageData.video && (
                        <Col lg={6}>
                            <EquireHeading>Got a Question? Enquire Now!</EquireHeading>
                            <ContactForm/>
                        </Col>
                    )}
                </Row>
            </Container>
        </FaqArea>
    )
}

export default Faq;