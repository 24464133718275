import React from 'react';
import { Container, Row, Col } from '../../components/ui/wrapper'
import Heading from '../../components/ui/heading'
import { BannerArea, BannerTextWrap } from './service-template.style'

const ServiceTemplate = ({ pageData, ...restProps }) => {
    const {
        titleStyle,
        taglineStyle,
    } = restProps;
    let bannerImg;
    if (pageData.banner_image) {
        bannerImg = pageData.banner_image.childImageSharp.fluid;
    }

    return (pageData.title || pageData.tagline) && (
        <BannerArea fluid={bannerImg}>
            <Container>
                <Row justify="center">
                    <Col lg={8}>
                        <BannerTextWrap>
                            {pageData.title && <Heading background {...titleStyle}>{pageData.title}</Heading>}
                            {pageData.tagline && <Heading {...taglineStyle}>{pageData.tagline}</Heading>}
                        </BannerTextWrap>
                    </Col>
                </Row>
            </Container>
        </BannerArea>
    )
}

export default ServiceTemplate;