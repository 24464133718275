import React from 'react';
import { Container, Row, Col } from '../../components/ui/wrapper'
import Heading from '../../components/ui/heading'
import { SectionWrap, PricingRow } from './service-template.style'
import PricingTable from '../../components/ui/pricing-table/layout-one'
import parse from 'html-react-parser'

const ServiceTemplate = ({ pageData }) => {
    console.log(pageData.pricing.plans.length > 3 ? 3 : 4)
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <Heading textalign="center" >{parse(pageData.pricing.title)}</Heading>
                    </Col>
                </Row>
                <PricingRow>
                    {pageData.pricing.plans.map(pricing => (
                        <Col lg={pageData.pricing.plans.length > 3 ? 3 : 4} md={6} key={pricing.id}>
                            <PricingTable
                                title={pricing.title}
                                image={pricing.image}
                                price={pricing.price}
                                period={pricing.period}
                                path={pricing.path}
                                features={pricing.features}
                                isFeatured={pricing.is_featured}
                                />
                        </Col>
                    ))}
                </PricingRow>
                <Row>
                    <Col lg={12}>
                        <p>{pageData.pricing.text}</p>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

export default ServiceTemplate;