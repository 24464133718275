import styled from 'styled-components'
import {device} from '../../theme'
import BackgroundImage from 'gatsby-background-image'
import { Row } from '../../components/ui/wrapper'

export const BannerArea = styled(BackgroundImage)`
    padding-top: 195px;
    padding-bottom: 204px;
`; 

export const BannerTextWrap = styled.div `
    text-align: center;
`;

export const IntroArea = styled.section `
    padding-top: 100px;
    padding-bottom: 94px;
    @media ${device.medium}{
        padding-top: 72px;
        padding-bottom: 50px;
    }
`;

export const FaqArea = styled.div `
    padding-bottom: 70px;
`;

export const VideoBoxWrap = styled.div `
    position: relative;
    @media ${device.medium}{
        margin-top: 30px;
    }
    img{
        border-radius: 5px;
    }
`;

export const VideoBtnWrap = styled.div `
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const FeatureArea = styled.section `
    padding-top: 68px;
    padding-bottom: 68px;
    @media ${device.medium}{
        padding-bottom: 55px;
    }
`;
export const SectionWrap = styled.section`
    padding-top: 30px;
    padding-bottom: 70px;
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 50px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 30px;
    }
`; 

export const StepWrap = styled.div`
    text-align: right;
    @media ${device.small}{
        text-align: left;
        margin-bottom: 42px;
    }
    h3{
        position: relative;
        font-size: 13px;
        line-height: 2em;
        letter-spacing: 2px;
        text-transform: uppercase;
        display: inline-block;
        padding: 35px 80px 35px 42px;
        z-index: 1;
        mark{
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            font-size: 120px;
            line-height: .8;
            font-weight: 900;
            color: #ededed;
            z-index: -1;
        }
        &:before{
            background-color: ${props => props.theme.colors.themeColor};
            content: '';
            position: absolute;
            top: 47px;
            left: 0;
            width: 34px;
            height: 1px;
        }
    }
`;

export const GradationRow = styled.div`
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    @media ${device.medium}{
        display: block;
    }
`;

export const PricingRow = styled(Row)`
    margin-top: 50px;
    justify-content: center;
`