import React from 'react';
import parse from 'html-react-parser'
import { Container, Box, Row, Col } from '../../components/ui/wrapper'
import Heading from '../../components/ui/heading'
import Text from '../../components/ui/text'
import { IntroArea } from './service-template.style'

const ServiceTemplate = ({ pageData, ...restProps }) => {
    const {
        introTextStyles: { leftHeading, rightHeading, rightText },
    } = restProps;

    return pageData.introText && (
        <IntroArea>
            <Container>
                <Row>
                    <Col lg={4}>
                        <Heading {...leftHeading}>{parse(pageData.introText.tagline)}</Heading>
                    </Col>
                    {pageData.introText && (
                        <Col lg={{ span: 7, offset: 1 }}>
                            <Box>
                                {pageData.introText.heading && <Heading {...rightHeading}>{parse(pageData.introText.heading)}</Heading>}
                                {pageData.introText.text && <Text {...rightText}>{parse(pageData.introText.text)}</Text>}
                            </Box>
                        </Col>
                    )}
                </Row>
            </Container>
        </IntroArea>
    )
}

export default ServiceTemplate;