import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from "../../components/seo"
import Layout from '../../containers/layout/layout'
import Header from '../../containers/layout/header/header-one'
import Footer from '../../containers/layout/footer/footer-one'
import ModalVideo from '../../components/ui/modal-video'
import CTA from '../../containers/global/cta-area/section-one'
import ResourcesArea from '../../containers/about-us/resources-area'
import Faq from './Faq'
import Pricing from './Pricing'
import Steps from './Steps'
import Features from './Features'
import Banner from './Banner'
import Intro from './Intro'

const ServiceTemplate = ({ data, location, ...restProps }) => {
    const pageData = data.itServicesJson;
    let bannerImg;
    if (pageData.banner_image) {
        bannerImg = pageData.banner_image.childImageSharp.fluid;
    }
    const [videoOpen, setVideoOpen] = useState(false);

    if (pageData.video) {
        var { video_link } = pageData.video;
        var video_arr = video_link.split('=', -1);
        var video_id = video_arr[1];
    }
    const modalVideoOpen = () => {
        setVideoOpen(true)
    }

    const modalVideoClose = () => {
        setVideoOpen(false)
    }

    return (
        <Layout location={location}>
            <SEO title={pageData.title} />
            <Header />
            <main className="site-wrapper-reveal">
                {(pageData.title || pageData.tagline) && (
                    <Banner
                        pageData={pageData}
                        {...restProps}
                    />
                )}
                {pageData.introText && (
                    <Intro
                        pageData={pageData}
                        {...restProps}
                    />
                )}
                {(pageData.faq || pageData.video) && (
                    <Faq 
                    pageData={pageData}
                    setVideoOpen={modalVideoOpen}
                    />
                )}
                {pageData.resource && (
                    <ResourcesArea
                        pageData={pageData}
                    />
                )}
                {pageData.features && (
                    <Features
                        pageData={pageData}
                        {...restProps}
                    />
                )}
                {pageData.pricing && (
                    <Pricing
                        pageData={pageData}
                    />
                )}
                {pageData.gradation && (
                    <Steps
                        pageData={pageData}
                    />
                )}
                <CTA />
            </main>
            <ModalVideo
                channel='youtube'
                videoId={video_id}
                isOpen={videoOpen}
                onClose={modalVideoClose}
            />
            <Footer />
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!){
        itServicesJson(fields: {slug: {eq: $slug}}){
            title
            id
            banner_image{
                childImageSharp{
                  fluid(maxWidth: 1920, maxHeight: 570, quality: 100){
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
            }
            introText{
                tagline
                heading
                text
            }
            faq{
                id
                title
                desc
            }
            features {
                title
                items {
                    id
                    title
                    desc
                }
            }
            resource {
                title
                subtitle
                download_link
                download_link_text
                download_link2
                download_link2_text
                image {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 570, maxHeight: 350) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                            presentationHeight
                            aspectRatio
                        }
                    }
                }
            }
            pricing {
                title
                text
                plans {
                    title
                    price
                    period
                    path
                    is_featured
                    features {
                        id
                        text
                    }
                }
            }
            gradation {
                title
                gradations {
                    id
                    title
                    desc
                    path
                    buttonText
                }
            }
        }
    }
`;

ServiceTemplate.propTypes = {
    introTextStyles: PropTypes.object,
    titleStyle: PropTypes.object,
    taglineStyle: PropTypes.object,
    featureStyels: PropTypes.object
}

ServiceTemplate.defaultProps = {
    titleStyle: {
        as: 'h1',
        color: '#fff',
        mb: '15px'
    },
    taglineStyle: {
        as: 'h5',
        fontweight: 400,
        color: '#fff'
    },
    introTextStyles: {
        leftHeading: {
            as: 'h3',
            mt: '25px',
            fontSize: '38px',
            child: {
                color: 'primary'
            },
            responsive: {
                large: {
                    fontSize: '30px'
                },
                medium: {
                    mt: 0,
                    mb: '20px',
                    fontSize: '28px'
                },
                small: {
                    fontSize: '24px'
                }
            }
        },
        rightHeading: {
            as: 'h5',
            position: 'relative',
            pl: '34px',
            lineheight: 1.67,
            fontweight: 800,
            layout: 'quote',
            child: {
                color: 'primary'
            },
        },
        rightText: {
            mt: '15px',
            fontSize: '18px',
            ml: '34px',
            color: '#696969'
        }
    },
    featureStyels: {
        featureTitle: {
            as: 'h3',
            textalign: 'center',
            mb: '20px'
        }
    }
}

export default ServiceTemplate;